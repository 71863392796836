<template>
  <div v-if="!loading">
    <div class="w-m-header">
      <div class="flex">
        <p class="font-14 font-bold no-margin col-grow" style="color: #293e52">Página do site</p>
      </div>
    </div>
    <div class="w-m-body e-input-modern flex">
      <div class="pages-container" style="overflow: hidden">
        <div class="flex w-full">
          <div class="pages-body" style="margin: auto !important;" >
            <div class="tbox">
              <div class="tbox-content">
                <pages-list :list="paginas" @select="openPage"></pages-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
  import WindowLoading from '../../../../../layout/window/Loading'
  import {list} from '../../../../../../domain/conteudo-paginas/services'
  import PagesList from '../include/PagesList'
  import windowPagina from '../window/windowPagina'

  export default {
    name: 'PaginasWindowModern',
    components: {PagesList, WindowLoading},
    props: ['props', 'id'],
    data () {
      return {
        paginas: [],
        loading: !!this.id,
        errors: null,
        success: false,
        status: null,
        source: false
      }
    },
    mounted () {
      this.load()
    },
    computed: {
    },
    methods: {
      load () {
        list(1000, 1, '')
                .then(response => {
                  this.paginas = response.data.result
                  console.log(this.paginas)
                  this.loading = false
                })
                .catch(error => {
                  this.alertApiError(error)
                  this.loading = false
                })
      },
      windowPagina,
      openPage({id}) {
        this.windowPagina(id)
      }
    }
  }
</script>
