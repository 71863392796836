<template>
  <div>
    <u-input placeholder="Pesquisar" autofocus class="input-search m-t" hide-underline
             v-model="search" />
    <ul class="pages-list">
      <li v-if="!filtered.length">
        <a>
          <p>Nenhum resultado encontrado</p>
        </a>
      </li>
      <li v-for="(page, index) in filtered" :key="index" @click="$emit('select', page)" v-else>
        <a>
          <u-icon class="t-icon" :name="page.icon || 'envelope'" type="fa" />
          <p>
            <strong>{{page.title}}</strong>
            {{page.description}}
          </p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  import {UInput} from 'uloc-vue'
  export default {
    name: 'PagesList',
    props: ['list'],
    components: {UInput},
    data () {
      return {
        search: null
      }
    },
    computed: {
      filtered () {
        if (!this.search) {
          return this.list
        }
        const containsSearchInProperty = (search, property) => String(property).toLowerCase().indexOf(search) > -1
        return this.list.filter(item => containsSearchInProperty(this.search, item.title) || containsSearchInProperty(this.search, item.description))
      }
    }
  }
</script>

<style scoped>

</style>
